@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
* {
    box-sizing: border-box;
	text-decoration: none;
}

.row{
	display: flex;
	
	flex-wrap: wrap;
}
.footer{
    color: var(--white);

    background-color: var(--dark-blue);
}
.footer-logo{
    width: 100px;
    height: 100px;
    margin: auto;
}
.footer-col ul{
	list-style: none;
	padding: 0;
}

.date-left{
    margin-right: 10px;
}
.footer-down{
    position: fixed;
    bottom: 0;
    width: 100%;
}


.footer-col{
   width: 25%;
   padding: 0 15px;
}
.footer-col h3{
	font-size: 18px;
	margin-bottom: 0px;
	font-weight: 500;
	position: relative;
    font-weight: bold;
    color: var(--white);
   
}
.footer-col ul{
    padding-top: 10px;
}
.footer-col h3::before{
	content: '';
	position: absolute;
	left:0;
	bottom: -10px;
	background-color: var(--light-brown);
	height: 2px;
	box-sizing: border-box;
	width: 50px;
}

.footer-col ul li a::before, .footer-col ul li address::before{
    margin-right: 12px;
}
.footer-col ul li a, .footer-col ul li {
	font-size: 16px;
    color: inherit;
	text-decoration: none;
	font-family: 'Roboto', sans-serif;
	padding-top: 1px;
font-weight: 300;
font-style: normal;
	display: block;
	transition: all 0.3s ease;
   
}
.contact ul li a{
    padding-bottom: 13px;
	max-width: 262px;
}
.footer-col ul li a:hover{
	opacity: 0.8;
	padding-left: 8px;
}
.footer-bottom{
	margin-top: 100px;
}
.footer-col .social-links a{
	display: inline-block;
	background-color: var(--dark-gray);
	margin:0 10px 10px 0;
	text-align: center;
	border-radius: 50%;
	transition: all 0.5s ease;
	width: 50px;
	height: 50px;
	opacity: 80%;
	box-shadow: rgba(0, 0, 0, 0.267);
}
.footer-col .social-links a img{
	padding: 10px;
}
.footer-col .social-links a:hover{
	color: #24262b;
	background-color: var(--dark-gray);
}
.footer-col ul .date{
    display: flex;
    justify-content: space-between;
    max-width: 220px;
	padding-top: 5px;
}
/*responsive*/

@media(max-width: 767px){
  .footer-col{
    width: 100%;
	flex: 1;
	padding: 10px;
}
.footer-col img{
	align-self: center;
}
.row{
	flex-direction: row;
}
.contact ul li a{
	padding-top: 6px;
	padding-bottom: 0;
}
.col-logo{
	flex: 2;
}
.col-services{
	flex: 1;
}
.col-contact{
	flex: 1;
}
.col-open{
	flex: 2;
}
.footer-col ul{
    margin: 0;
}
.footer-col h4::before{
    bottom: -5px
}
.footer-bottom{
	margin-top: 0;
}

}
@media(max-width: 574px){

.footer-down{
    position: initial;
}

}
