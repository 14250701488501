.contact-page {
    display: flex;
    justify-content: center;
    margin: 20px;

  }
  
  .contact-form {
    flex: 1;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .contact-form h2 {
    margin-bottom: 15px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group textarea,
  .form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    background-color: var(--white);
  }
  
  .form-group select {
    appearance: none;
  }
  
  button[type="submit"] {
    background-color: var(--light-brown);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin-left: 20px;
    transition: background-color 0.3s ease;
  }
  
  button[type="submit"]:hover {
    background-color: var(--brown);
  }
  
  .contact-info {
    flex: 1;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .contact-info h2 {
    margin-bottom: 15px;
  }
  
  .contact-list {
    margin-top: 15px;
  }
  .form-group textarea {
    resize: none;
  }
  .contact-item {
    display: flex;
  }
  .contact-item span {
    font-weight: bold;
    margin-right: 5px;
    min-width: 60px;
  }
  .contact-item span.contact-name-side{
    font-weight: normal;
    text-align: left;
  }
  .contact-together .contact-item{
   margin: 0;
    margin-bottom: 5px;
  }
  .contact-info h2{
    margin-left: 20px;
    font-size: 1.5em;

  }

.contact-wrapper div{
    margin: 20px;
    max-width: 400px;
    min-width: 295px;
}

      