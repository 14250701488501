.main-image{
   height: 80vh;
   position: relative;
   top: -160px;
   margin-bottom: -160px;
   background-size: 130%;
   background-size: cover;
   background-position: center left;
   background-repeat: no-repeat;
}

.main-image {
    position: relative;
    background-image: url("../public/img/background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .content {
    min-height: calc(100vh - 492px); /* 100px je výška footeru, uprav podle potřeby */
}
.content-2 {
  min-height: calc(100vh - 594px); /* 100px je výška footeru, uprav podle potřeby */
}

  
  .main-image::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../public/svg/lines.svg");
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.5; /* Adjust the opacity as needed */
  }
  .aktualita-content{
    min-height: 700px;
  }
.main-title{
    color: var(--white);
}
.main{
    height: 80vh;
}
.main-wrapper{
    color: var(--white);
    position: relative;
    background-position: bottom;
    background-size: cover;
    margin-top: -3px;
}
.main-title-large{

    font-size: 70px;
}
.main-wrapper h1{
    
    max-width: 990px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20vh;
    text-align: center;
}
.main-wrapper h2{
    font-size: 2em;
    display: flex;
    justify-content: center;
}
.btn{
    padding: 10px 36px;
    
    border: 3px solid var(--light-brown);
    background: none;
    border-radius: 13px;
    color: var(--white);
    display: block;
    margin: auto;
    margin-top: 30px;
    
}
.btn {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    font-size: 1.1em;
    font-weight: bold;
    cursor: pointer;
   
}
.btn:hover{
  cursor: pointer;
}
.btn-nav{
    display: flex;
    position: relative; /* Add this */
  z-index: 1;
}
.btn-black{
    color: var(--black);
}
  .btn:hover{
    cursor: pointer;
  }
  .btn:hover .arrow-icon {
    transform: translateX(10px);
  }
  
  .arrow-icon {
    display: inline-block;
    width: 20px;
    margin-left: 10px;
    height: 20px;
    background: url('../public/svg/arrow.svg') center/contain no-repeat;
    transition: transform 0.3s ease;
  }
  .arrow-icon-dark{
    background: url('../public/svg/arrow-dark.svg') center/contain no-repeat;
  }
  .aktualita-text{
    margin: 10px;
    line-height: 1.5;
  }
  .aktualita-text:first-child {
    font-size: 1.2em;
  }
.line{
    background-color: var(--light-brown);
    height: 3px;
    max-width: 800px;
    border: 0;
    border-radius: 2px;
}

.onas-wrapper {
    display: flex;
}

.onas-content, .onas-bottom-article {
    margin: 50px;
}
.onas-content p, .onas-bottom-article p{
    line-height: 1.6;
}
.onas-content h2, .onas-bottom-article h2{
    font-size: 2em;
    margin-left: 0;
    text-align: start;
}
.sluzba-box{
    background-color: var(--dark-blue);
    color: var(--white);
    margin: 20px;
    max-width: 292px;
    min-height: 182px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sluzba-box:hover h2{
    border-color: var(--brown);
    opacity: .8;
}
.onas-text{
    padding-top: 20px;
    margin-bottom: 0;
    font-size: 1.3em;
}
.onas-content{
    margin-top: 0;
    text-align: justify;
}
.sluzba-box h2 {
    margin: 2em 2em;
   border-bottom: var(--light-brown) solid 3px;
    font-size: 1.6em;
    padding-bottom: 5px;
    transition: ease-in .2s;
   
}
.sluzby-box{
 display: flex;
 flex-wrap: wrap;
}
.details-text{
    margin: 20px;
    margin-bottom: 1em;
}
.details-wrapper{
    display: flex;
    margin-top: 2em;
}
.text-side{
    max-width: 850px;
    line-height: 1.5;
}
.list-side{
    margin: 20px;
    max-width: 500px;
}
.details .btn {
    margin: 20px;
}
.list-side ul {
    list-style: none;
    padding: 0;
  }
  .details-article h2{
    font-size: 1.5em;
    margin-left: 0;
  }
  .details-article{
    margin: 20px;
  }
  .list-side li {
    padding-left: 30px;
    position: relative;
    padding: 12px;
    padding-left: 38px;
  }
  
  .list-side li::before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border: 2px solid var(--light-brown);
    border-radius: 50%;
    position: absolute;
    left: 0;
    margin-left: 15px;
    margin-top: 2.5px;
  }
  
  .list-side li:nth-child(odd) {
    background-color: #EAEAEA;
  }
  
  .list-side li:nth-child(even)::before {
    background-color: #FBFBFC;

  }
  .contact-wrapper{
    display: flex;
    justify-content: center;
  }
 /* Style for the aktuality-box component */
.aktuality-box {
    display: block;
    margin-bottom: 20px;
    text-decoration: none;
    color: #333;
  }
  .aktuality h3{
    margin-bottom: 10px;
  }
  .btn-aktuality{
    margin-top: 10px;
    font-size: 0.8em;
    padding: 7px 12px;
  }
  
  /* Style for the box content */
  .box-content {
    padding: 20px;
    /* border: 3px solid var(--brown); */
    border-radius: 12px;
    transition: transform 0.2s, box-shadow 0.2s;
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
  
    /* Hover effect */
    
  }
  .box-content:hover {
    transform: translateY(-3px);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  }
  .contact-name-side a{
    color: inherit;
    transition: .2s ease-in;
  }
  .contact-name-side a:hover{
    color: var(--light-brown);
    opacity: .8;
  }
  
  /* Style for the box title */
.box-title {
    font-size: 24px;
    margin-bottom: 10px;
    text-decoration: none; /* Remove the default underline */
    position: relative;
    margin-left: 0;
    color: initial;
    
  }
  .box-title::after {
    content: '';
    display: block;
    width: 130px;
    height: 3px;
    background-color: var(--light-brown); /* Light brown underline color */
    position: absolute;
    bottom: -4px; /* Adjust as needed */
    left: 0;
  }
  
  
  /* Style for the box short text */
  .box-short-text {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  /* Style for the box date */
  .box-date {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 14px;
    color: #999;
  }
  
  @media (max-height: 720px) {
    .main-image{
      height: 85vh;
    }
  }

  @media (max-width: 768px) {
    .list-side h3{
      font-size: 20px;
    }
    .details .btn {
      margin: 10px;
      margin-left: 0;
      
  }
  .text-side{
    line-height: 1.1;
  }
    .main-wrapper{
        padding-top: 5em;
    }
    .main-wrapper h1{
        font-size: 30px;
    }
    .line{
        margin-left: 20px;
        margin-right: 20px;
    }
    .main-wrapper h2{
        font-size: 1.3em;
        margin-left: 20px;
    }
    .main-image{
        height: 70vh;
        
    }
    .onas-wrapper{
        flex-direction: column;
    }
    .onas-content, .onas-bottom-article {
        margin: 0;
    }
    .onas-content p, .onas-bottom-article p {
      line-height: 1.2;
    }
    .sluzba-box {
        max-width: none;
        margin-bottom: 5px;
        margin-top: 5px;
    }
    .details-wrapper{
        flex-direction: column;
        margin-top: 10px;
    }
    .details-text {
        margin-bottom: 0;
           margin-top: 5px;
           margin: 0.2em;
    }
    .list-side {
      margin: 0.2em;
    }
    .contact-wrapper {

        flex-direction: column;
        align-items: center;
    }
    .main-title-large {
        font-size: 35px;
    }
    .main-wrapper h1 {
        padding-top: 3em;
        margin: 10px;
    font-size: 1.5em;

    }
    .subject-title{
      font-size: 1.5em;
    }
}

@media (max-width: 840px){
.details-wrapper {
    flex-direction: column;
    margin-top: 10px;
}
}
@media (max-width: 375px) {
  .main-image{
    height: 75vh;
  }
}
@media (max-width: 1900px) {
  .aktualita-content{
    min-height: 400px;
  }
}