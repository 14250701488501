
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');
body{
  margin: 0;
}
:root {
  --brown: #735E36;
  --light-brown: #C69D36;
  --dark-blue: #242239;
  --white: #FFFFFF;
  --black: #28283E;
}

h1,
h2,
h3,
h4 {
  font-family: 'Playfair Display', serif;
  color: var(--black);
}

h1 {
  font-size: 36px;
  margin-left: 10px;
  font-weight: 400;
  padding: 0;
}

h2 {
  color: var(--red);
  font-weight: 400;
  margin-left: 10px;
  font-size: 1.8em;
  margin-top: 0;
  margin-bottom: 0;
}

h3 {
  font-size: 24px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
}

body, textarea {
  font-family: 'Roboto', sans-serif;
font-weight: 400;
font-style: normal;
  color: var(--dark-gray);
  background-color: var(--light-gray);
  


}
address {
  font-style: inherit;
}

p{
  margin: .2em;
}

.link{
  color: #242239;
  font-weight: bold;
  font-size: 1.1em;
  transition: ease-in .2s;

}
.link:hover{
  color: #C69D36;
}
main {

  margin-left: auto;
  margin-right: auto;
}

.none {
  position: absolute;
  left: -9999px;
}

.logo {

  width: 200px;
}
.max-width {
  max-width: 1490px;
  margin-left: auto;
  margin-right: auto;
  line-height: normal;
  padding: 10px;

}
.poukazka {
  margin-top: 30px;
  width: 100%;
  height: 640px;
  background-repeat: no-repeat;
  background-size: contain;

}

@media only screen and (min-width:920px) {
  h1 {
      font-size: 45px;
  }

  h2 {
      margin-top: 20px;
      font-size: 3em;
  }
}

html {
  scroll-behavior: smooth;
  min-height: 100vh;
}


.button{
  padding: 20px 34px;
  border: 0;
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}
.button:hover{
  cursor: pointer;
}
.text-block p {
  padding: 5px;
}
.lg-react-element{
display: flex;
overflow-x: auto;
}
@media (min-width:920px) {
  .lg-react-element{
    display: block;
    }
}


.lg-thumb.lg-group{
  display: none !important;
}