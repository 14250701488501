.top-header-wrapper ul {
    display: flex;
    justify-content: center;
    text-decoration: none;
    background-color: var(--brown);
    padding: .5em;
    margin: 0;
    align-items: flex-end;
    
}
.header{
    position: relative;
    z-index: 999;
}
.index-top{
    z-index: 999;
}
.bottom-header-wrapper nav ul li a {
    font-weight: bold;
    transition: ease-in .2s;
}
.bottom-header-wrapper nav ul li a:hover {
    color: var(--light-brown);
}
.header ul li a:hover {
    opacity: 0.8;
}
.top-header-wrapper ul li{
    text-decoration: none;
    display: block;

}
.top-header-wrapper ul li a, .top-header-wrapper ul li address{
    text-decoration: none;
    padding: .5em;
    color: inherit;
    color: var(--white);
    transition: ease-in .2s;
}
.header ul li a:hover{
    opacity: 0.8;

}
.header ul li a{
    display: block;
}
.mail:before {
    content: "";
    display: block;
    background: url("../../public/svg/mail.svg") no-repeat;
    width: 28px;
    height: 27px;
    float: left;

}
.bottom-header .menu-btn {
    display: none;
}
.top-header-wrapper ul li a::before{
    margin-right: 10px;
}
.blue-background{
    padding-bottom: 20px;
    background-color: var(--dark-blue);
}
.phone:before {
    content: "";
    display: block;
    background: url("../../public/svg/phone.svg") no-repeat;
    width: 28px;
    height: 27px;
    float: left;
}
.address:before {
    content: "";
    display: block;
    background: url("../../public/svg/map.svg") no-repeat;
    width: 28px;
    height: 27px;
    float: left;
}
.bottom-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.bottom-header-wrapper nav ul {
    display: flex;
}
.bottom-header-wrapper nav ul li{
    display: inline;
}
.bottom-header-wrapper nav ul li a{
    text-decoration: none;
    color: var(--white);
    padding: 10px;

}
.subject-title{
    color: var(--white);
    display: flex;
    justify-content: center;
    max-width: 767px;
    text-align: center;
    margin: auto;
    border-bottom: 3px solid var(--light-brown);
}
.title-width {
    max-width: 900px;
}
.bottom-header .menu-btn {
    display: none;
  }
  
  .bottom-header .menu-btn:checked ~ .bottom-header nav {
    height: 240px;
  }
  
  .bottom-header .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
  }
  
  .bottom-header .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
  }
  
  .bottom-header .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
  }
  
  .bottom-header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
  .bottom-header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
  }
 

@media (max-width: 768px) {
    .top-header{
        display: none
    }
    .bottom-header ul{
        flex-direction: column;
    }
    .bottom-header .menu-icon {
        cursor: pointer;
        display: inline-block;
        position: relative;
        user-select: none;
    }
    .bottom-header .menu-icon .navicon {
        background: var(--white);
        display: block;
        height: 4px;
        position: relative;
        transition: background .2s ease-out;
        width: 30px;
        border-radius: 10px;
    }
    .bottom-header .menu-icon .navicon:before, .header .menu-icon .navicon:after {
        background: var(--white);
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        transition: all .2s ease-out;
        width: 100%;
        border-radius: 10px;
    }
   
    .bottom-header .menu-icon .navicon:before {
        top: 10px;
    }
    .bottom-header .menu-icon .navicon:after {
        top: -10px;
    }
    .bottom-header-wrapper nav ul{
        position: absolute;
        top: 0;
    }
    
    
    .bottom-header nav ul {
        position: absolute;
        opacity: 0;
        top: -200px;
        right: 0;
        width: 100vw;
        z-index: 1;
        background-color: var(--dark-blue);
    }
    .bottom-header .menu-active ~ .bottom-header nav ul{
       
        background-color: var(--dark-blue);
        top: 90px;
        opacity: 100%;
        z-index: 999;
    }
    .bottom-header-wrapper nav ul li a {
        text-align: end;
        padding-right: 50px;
    }
    .bottom-header .menu-active ul{
        opacity: 100%;
        top: -100px;

      }


/* Navigation Styles */
.bottom-header-wrapper nav ul {
  position: absolute;
  top: -475px; 
  right: 0;
  width: 100vw;
  z-index: 1;
  background-color: var(--dark-blue);
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  padding: 10px 0;
  padding-top: 150px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}


/* Active Menu Styles */
.bottom-header .menu-active ~ .bottom-header-wrapper nav ul {
  opacity: 1;
  transform: translateY(0);
}

/* Navigation Item Styles */
.bottom-header-wrapper nav ul li {
  text-align: right;
  margin-right: 20px;
  padding: 10px 0;
  list-style: none;
}

.bottom-header-wrapper nav ul li a {
  color: var(--white);
  text-decoration: none;
  font-weight: bold;
  transition: color 0.2s ease-in-out;
}

.bottom-header-wrapper nav ul li a:hover {
  color: var(--light-brown);
}

}
@media (max-width: 860px){
    .contact ul li a::before, .address::before, .top-header-wrapper ul li a::before{
        display: none !important;
    }
    .subject-title{
        margin-left: 20px;
    margin-right: 20px;
    }
    }
